var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { JOIN_TEAM_FUNDRAISING_MUTATION } from '@vizsla/graphql';
import { TeamFundraisingRole } from '@vizsla/types';
export function useJoinTeamFundraising() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [execute] = useMutation(JOIN_TEAM_FUNDRAISING_MUTATION);
    function joinTeam(options) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (!options.team.id) {
                throw new TypeError(`The ID from team must be a string. Got ${typeof options.team.id}`);
            }
            if (!options.attendee.id) {
                throw new TypeError(`The ID from attendee must be a string. Got ${typeof options.team.id}`);
            }
            setLoading(true);
            try {
                const res = yield execute({
                    variables: {
                        membership: {
                            role: TeamFundraisingRole.TEAM_MEMBER,
                            attendee: { connect: { id: (_a = options.attendee) === null || _a === void 0 ? void 0 : _a.id } },
                            team: { connect: { id: (_b = options.team) === null || _b === void 0 ? void 0 : _b.id } },
                        },
                    },
                    refetchQueries: ['AttendeeManagement'],
                    awaitRefetchQueries: true,
                });
                const result = (_c = res.data) === null || _c === void 0 ? void 0 : _c.membership;
                setData(result);
                setLoading(false);
                return result;
            }
            catch (err) {
                setLoading(false);
                throw err;
            }
        });
    }
    return {
        data,
        loading,
        joinTeam,
    };
}
