import { useMemo } from 'react';
import { createFilterBuilder, useFormQuestionsQuery, } from '@vizsla/graphql';
/**
 * Fetch the `DefaultQuestions` and `CustomQuestions` based on the given `params` and filter.
 * https://8base-dev.atlassian.net/browse/VSL-2063
 */
export function useFormQuestions(params) {
    var _a, _b;
    const options = params;
    const filterDefaultQuestions = useMemo(() => {
        var _a;
        const filter = createFilterBuilder();
        if ((_a = params.variables) === null || _a === void 0 ? void 0 : _a.filter) {
            filter.and(params.variables.filter);
        }
        return filter.build();
    }, [params.variables]);
    const filterCustomQuestions = useMemo(() => {
        var _a;
        const filter = createFilterBuilder();
        if ((_a = params.variables) === null || _a === void 0 ? void 0 : _a.filter) {
            filter.and(params.variables.filter);
        }
        return filter.build();
    }, [params.variables]);
    const { data: response, loading, refetch, } = useFormQuestionsQuery(Object.assign(Object.assign({}, options), { variables: {
            filterDefaultQuestions,
            filterCustomQuestions,
        } }));
    const defaultQuestions = (_a = response === null || response === void 0 ? void 0 : response.defaultQuestions.items) !== null && _a !== void 0 ? _a : [];
    const customQuestions = (_b = response === null || response === void 0 ? void 0 : response.customQuestions.items) !== null && _b !== void 0 ? _b : [];
    return {
        defaultQuestions,
        customQuestions,
        loading,
        refetch,
    };
}
