import { useCampaignFaQsListQuery } from '@vizsla/graphql';
import { CampaignFaqAllocation } from '@vizsla/types';
export const usePublicCampaignFAQs = (userGroup, experience, campaign) => {
    const filterByCampaignId = {
        campaign: {
            id: {
                equals: campaign === null || campaign === void 0 ? void 0 : campaign.id,
            },
        },
    };
    const { data, loading } = useCampaignFaQsListQuery({
        variables: {
            filter: filterByCampaignId,
        },
    });
    const campaignFaqs = (data === null || data === void 0 ? void 0 : data.campaignFAQSList.items) || [];
    const campaignFaqExperience = campaignFaqs.filter(faq => {
        var _a;
        const allocationIdArray = (_a = faq.allocation) === null || _a === void 0 ? void 0 : _a.items.map(experience => experience.id);
        return (allocationIdArray === null || allocationIdArray === void 0 ? void 0 : allocationIdArray.includes(experience)) ? faq : null;
    });
    const campaignByOrder = {
        [CampaignFaqAllocation.Attendee]: [],
        [CampaignFaqAllocation.Donor]: [],
        campaign: campaign !== null && campaign !== void 0 ? campaign : {},
    };
    campaignFaqExperience.forEach((faq) => {
        var _a, _b;
        if ((_a = faq === null || faq === void 0 ? void 0 : faq.userGroup) === null || _a === void 0 ? void 0 : _a.includes(CampaignFaqAllocation.Donor)) {
            campaignByOrder === null || campaignByOrder === void 0 ? void 0 : campaignByOrder[CampaignFaqAllocation.Donor].push(faq);
        }
        if ((_b = faq === null || faq === void 0 ? void 0 : faq.userGroup) === null || _b === void 0 ? void 0 : _b.includes(CampaignFaqAllocation.Attendee)) {
            campaignByOrder === null || campaignByOrder === void 0 ? void 0 : campaignByOrder[CampaignFaqAllocation.Attendee].push(faq);
        }
    });
    const allocationCampaignFaqs = campaignFaqExperience.filter(faq => { var _a; return (_a = faq === null || faq === void 0 ? void 0 : faq.userGroup) === null || _a === void 0 ? void 0 : _a.includes(userGroup); });
    return {
        allocationCampaignFaqs,
        campaignFaqExperience: campaignByOrder,
        loading,
    };
};
