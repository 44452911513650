import { create } from 'zustand';
export const useOrganizationImpactFilters = create(set => {
    const INITIAL_FILTERS = {};
    const INITIAL_STATE = {
        strategy: 'BY_ALL',
        filters: INITIAL_FILTERS,
    };
    function filterByAll() {
        set({
            strategy: 'BY_ALL',
            filters: INITIAL_FILTERS,
        });
    }
    function filterByThisYear() {
        const year = new Date().getFullYear();
        const firstDay = new Date(year, 0, 0).toISOString();
        const lastDay = new Date(year, 11, 31).toISOString();
        set({
            strategy: 'BY_THIS_YEAR',
            filters: {
                startDate: {
                    gte: firstDay,
                },
                endDate: {
                    lte: lastDay,
                },
            },
        });
    }
    function clean() {
        set(INITIAL_STATE);
    }
    function filterByCustomDates(firstDay, lastDay) {
        set({
            strategy: 'BY_CUSTOM_DATES',
            filters: {
                startDate: {
                    gte: firstDay,
                },
                endDate: {
                    lte: lastDay,
                },
            },
        });
    }
    return Object.assign(Object.assign({}, INITIAL_STATE), { filterByAll,
        filterByThisYear,
        filterByCustomDates,
        clean });
});
