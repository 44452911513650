import { useMemo } from 'react';
import { createFilterBuilder, } from '@vizsla/graphql';
import { CampaignQuestionUserGroup } from '@vizsla/types';
import { normalizeCustomQuestion, normalizeDefaultQuestion, filterQuestionsByUserGroup, } from '@vizsla/utils';
import { useFormQuestions } from './useFormQuestions';
/**
 * Fetch and normalize the questions for **attendee's form** based on the given `experience`.
 * https://8base-dev.atlassian.net/browse/VSL-2063
 */
export function useAttendeeQuestions(options) {
    const filter = useMemo(() => {
        var _a;
        const filter = createFilterBuilder();
        filter.and({ isEnabledAttendee: { equals: true } });
        const experience = {
            id: { equals: (_a = options.variables) === null || _a === void 0 ? void 0 : _a.experience.id },
        };
        filter.and({
            OR: [
                { experience: { some: experience } },
                { campaign: { experiences: { some: experience } } },
            ],
        });
        return filter.build();
    }, [options.variables]);
    const { defaultQuestions, customQuestions, loading } = useFormQuestions({
        variables: { filter },
    });
    /** Normalize the `defaultQuestions` and `customQuestions` satisfing the `Question` interface. */
    const questions = useMemo(() => {
        const questions = [];
        for (const received of defaultQuestions) {
            const question = normalizeDefaultQuestion(received);
            if (received.isRequiredAttendee) {
                // Mark as mandatory ONLY in this context
                // Because might not be required on other groups
                question.isMandatory = true;
            }
            questions.push(question);
        }
        // Since userGroup field is not available as a filter argument in the CustomCampaignQuestionsList query,
        // there's no way to distinguish between donors, attendee or team questions so we need to filter after getting all data.
        const filteredCustomQuestions = filterQuestionsByUserGroup(customQuestions, CampaignQuestionUserGroup.attendee);
        for (const received of filteredCustomQuestions) {
            const question = normalizeCustomQuestion(received);
            if (received.isRequiredAttendee) {
                // Mark as mandatory ONLY in this context
                // Because might not be required on other groups
                question.isMandatory = true;
            }
            questions.push(question);
        }
        return questions;
    }, [defaultQuestions, customQuestions]);
    return {
        questions,
        loading,
    };
}
