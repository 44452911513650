import { getFriendlyTimeZone } from '@vizsla/utils';
import { useGetOrganizationByExperienceQuery } from '@vizsla/graphql';
export const useTimeZoneByAddress = ({ address, organizationTimeZone = 'UTC', experience, }) => {
    var _a, _b, _c, _d;
    const { data: organizationData, loading } = useGetOrganizationByExperienceQuery({
        variables: {
            id: experience === null || experience === void 0 ? void 0 : experience.id,
        },
    });
    let timeZone;
    if (!loading) {
        timeZone =
            ((_a = organizationData === null || organizationData === void 0 ? void 0 : organizationData.experience) === null || _a === void 0 ? void 0 : _a.timeZone) ||
                ((_d = (_c = (_b = organizationData === null || organizationData === void 0 ? void 0 : organizationData.experience) === null || _b === void 0 ? void 0 : _b.campaign) === null || _c === void 0 ? void 0 : _c.vizslaOrganization) === null || _d === void 0 ? void 0 : _d.timeZone) ||
                'utc';
    }
    else {
        timeZone = '';
    }
    const formattedTimeZone = getFriendlyTimeZone(timeZone);
    return { timeZone, formattedTimeZone };
};
