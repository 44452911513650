import { useGetRecentActivityQuery, } from '@vizsla/graphql';
export function useGetRecentActivity(variables) {
    var _a, _b, _c, _d;
    const { data: response, loading } = useGetRecentActivityQuery({
        variables,
    });
    const count = (_b = (_a = response === null || response === void 0 ? void 0 : response.getRecentActivity) === null || _a === void 0 ? void 0 : _a.totalActivities) !== null && _b !== void 0 ? _b : 0;
    const data = (_d = (_c = response === null || response === void 0 ? void 0 : response.getRecentActivity) === null || _c === void 0 ? void 0 : _c.activities) !== null && _d !== void 0 ? _d : [];
    const formattedData = data.map((item) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return ({
            activityDate: (_a = item === null || item === void 0 ? void 0 : item.activityDate) !== null && _a !== void 0 ? _a : '',
            activityType: (_b = item === null || item === void 0 ? void 0 : item.activityType) !== null && _b !== void 0 ? _b : '',
            donationAmount: (_c = item === null || item === void 0 ? void 0 : item.donationAmount) !== null && _c !== void 0 ? _c : 0,
            fullName: (_d = item === null || item === void 0 ? void 0 : item.fullName) !== null && _d !== void 0 ? _d : '',
            firstName: (_e = item === null || item === void 0 ? void 0 : item.firstName) !== null && _e !== void 0 ? _e : '',
            lastName: (_f = item === null || item === void 0 ? void 0 : item.lastName) !== null && _f !== void 0 ? _f : '',
            id: (_g = item === null || item === void 0 ? void 0 : item.id) !== null && _g !== void 0 ? _g : '',
            avatar: (_h = item === null || item === void 0 ? void 0 : item.avatar) !== null && _h !== void 0 ? _h : null,
            registrationName: (_j = item === null || item === void 0 ? void 0 : item.registrationName) !== null && _j !== void 0 ? _j : '',
        });
    });
    return { data: formattedData, loading, count };
}
