import { Application, OrganizationRoles } from '@vizsla/types';
import { getUserCurrentApplication, getUserRoles, getAppIdByOrganizationRole } from '@vizsla/utils';
import { useCurrentUser } from '..';
const getAvailableAppsList = (user) => {
    const userRoles = getUserRoles(user);
    const availableAppsList = [];
    for (const userRole of userRoles.system) {
        availableAppsList.push({
            id: getAppIdByOrganizationRole(userRole),
            roleName: userRole,
        });
        if (getAppIdByOrganizationRole(userRole) === Application.OrganizationAdminClient) {
            availableAppsList.push({
                id: Application.CheckInClient,
                roleName: OrganizationRoles.Checkiner,
            });
        }
    }
    return availableAppsList;
};
const emptyState = {
    loading: false,
    availableAppsList: [],
    currentApp: null,
};
export const useUserCurrentApp = (currentSection) => {
    const { user, loading } = useCurrentUser(currentSection);
    if (loading) {
        return Object.assign(Object.assign({}, emptyState), { loading });
    }
    const currentApp = getUserCurrentApplication(user);
    const availableAppsList = getAvailableAppsList(user);
    const isUnknownCurrentApp = currentApp === Application.Unknown || !Object.values(Application).includes(currentApp);
    if (availableAppsList.length === 1) {
        return {
            loading,
            currentApp: availableAppsList[0].id,
            availableAppsList,
        };
    }
    return {
        loading,
        currentApp: isUnknownCurrentApp ? null : currentApp,
        availableAppsList,
    };
};
