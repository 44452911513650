var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useAllocatedDonationsLazyQuery } from '@vizsla/graphql';
/** Fetchs all the allocated donations to someone. */
export function useAllocatedDonationsQueries() {
    const [executeQuery] = useAllocatedDonationsLazyQuery();
    /**
     * Fetchs all the donations allocated into a fundraising team.
     *
     * @param teamId ID from `Team Fundraising` table.
     */
    const byTeamFundraising = useCallback((teamId, options) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const filters = (_a = options === null || options === void 0 ? void 0 : options.filters) !== null && _a !== void 0 ? _a : [];
        const filter = {
            AND: [
                {
                    teamFundraising: { id: { equals: teamId } },
                },
                {
                    parents: {
                        every: {
                            id: {
                                is_empty: true,
                            },
                        },
                    },
                },
            ],
        };
        const response = yield executeQuery({
            variables: Object.assign({ filter: { AND: [...filters, filter] } }, options === null || options === void 0 ? void 0 : options.pagination),
        });
        return (_b = response.data) === null || _b === void 0 ? void 0 : _b.donations;
    }), []);
    /**
     * Fetchs all the donations allocated into a fundraiser.
     *
     * @param fundraiserId ID from `Individual Fundraising` table.
     */
    const byIndividualFundraising = useCallback((fundraiserId, options) => __awaiter(this, void 0, void 0, function* () {
        var _c, _d;
        const filters = (_c = options === null || options === void 0 ? void 0 : options.filters) !== null && _c !== void 0 ? _c : [];
        const fundraiserFilter = {
            individualFundraising: {
                id: {
                    equals: fundraiserId,
                },
            },
            amount: {
                gt: 0,
            },
        };
        const response = yield executeQuery({
            variables: Object.assign({ filter: { AND: [...filters, fundraiserFilter] } }, options === null || options === void 0 ? void 0 : options.pagination),
        });
        return (_d = response.data) === null || _d === void 0 ? void 0 : _d.donations;
    }), []);
    return {
        byTeamFundraising,
        byIndividualFundraising,
    };
}
