var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CAMPAIGN_HEADER_IMAGE, CAMPAIGN_HEADER_IMAGE_UPDATE, CLIENT_CAMPAIGN_QUERY, EXPERIENCE_HEADER_IMAGE_UPDATE, } from '@vizsla/graphql';
import { CampaignType } from '@vizsla/types';
export function useCampaignHeroImage() {
    const { campaignId } = useParams();
    const [data, setData] = useState();
    const [fetching, setFetching] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [find] = useLazyQuery(CAMPAIGN_HEADER_IMAGE);
    const [execute] = useMutation(CAMPAIGN_HEADER_IMAGE_UPDATE);
    const [executeExperience] = useMutation(EXPERIENCE_HEADER_IMAGE_UPDATE);
    useEffect(() => {
        if (campaignId) {
            fetchByCampaign(campaignId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId]);
    const hasData = useMemo(() => { var _a; return Boolean((_a = data === null || data === void 0 ? void 0 : data.downloadUrl) === null || _a === void 0 ? void 0 : _a.length); }, [data]);
    function fetchByCampaign(id) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            setFetching(true);
            const response = yield find({
                variables: { id },
            });
            const result = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.campaign) === null || _b === void 0 ? void 0 : _b.image) !== null && _c !== void 0 ? _c : undefined;
            setFetching(false);
            setData(result);
        });
    }
    function detach() {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            if (!data)
                return;
            setUpdating(true);
            const payload = {
                fileId: data.fileId,
            };
            let result;
            try {
                result = yield execute({
                    variables: {
                        filter: { id: campaignId },
                        data: {
                            image: {
                                disconnect: payload,
                            },
                        },
                    },
                    refetchQueries: [{ query: CLIENT_CAMPAIGN_QUERY, variables: { id: String(campaignId) } }],
                    awaitRefetchQueries: true,
                });
                setUpdating(false);
                setData(undefined);
            }
            catch (err) {
                setUpdating(false);
                throw err;
            }
            finally {
                if (result && ((_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.campaign) === null || _b === void 0 ? void 0 : _b.campaignType) === CampaignType.impactGiving) {
                    yield executeExperience({
                        variables: {
                            filter: { id: (_f = (_e = (_d = (_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.campaign) === null || _d === void 0 ? void 0 : _d.experiences) === null || _e === void 0 ? void 0 : _e.items[0]) === null || _f === void 0 ? void 0 : _f.id },
                            data: {
                                experienceHeaderImage: {
                                    disconnect: payload,
                                },
                            },
                        },
                    });
                }
            }
        });
    }
    function attach(file) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function* () {
            setUpdating(true);
            const payload = {
                fileId: file.fileId,
                filename: file.filename,
                meta: file.meta,
                mods: file.mods,
            };
            let result;
            try {
                result = yield execute({
                    variables: {
                        filter: { id: campaignId },
                        data: {
                            image: {
                                create: payload,
                            },
                        },
                    },
                    refetchQueries: [{ query: CLIENT_CAMPAIGN_QUERY, variables: { id: String(campaignId) } }],
                    awaitRefetchQueries: true,
                });
                const response = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.campaign) === null || _b === void 0 ? void 0 : _b.image) !== null && _c !== void 0 ? _c : {};
                if ('downloadUrl' in response) {
                    setUpdating(false);
                    setData(response);
                }
            }
            catch (err) {
                setUpdating(false);
                throw err;
            }
            finally {
                if (result && ((_e = (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.campaign) === null || _e === void 0 ? void 0 : _e.campaignType) === CampaignType.impactGiving) {
                    yield executeExperience({
                        variables: {
                            filter: { id: (_j = (_h = (_g = (_f = result === null || result === void 0 ? void 0 : result.data) === null || _f === void 0 ? void 0 : _f.campaign) === null || _g === void 0 ? void 0 : _g.experiences) === null || _h === void 0 ? void 0 : _h.items[0]) === null || _j === void 0 ? void 0 : _j.id },
                            data: {
                                experienceHeaderImage: {
                                    connect: { fileId: payload.fileId },
                                },
                            },
                        },
                    });
                }
            }
        });
    }
    function replace(file) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function* () {
            if (!data)
                return;
            setUpdating(true);
            const payload = {
                fileId: file.fileId,
            };
            const actual = {
                fileId: data.fileId,
            };
            let result;
            try {
                result = yield execute({
                    variables: {
                        filter: { id: campaignId },
                        data: {
                            image: {
                                disconnect: actual,
                                create: payload,
                            },
                        },
                    },
                    refetchQueries: [{ query: CLIENT_CAMPAIGN_QUERY, variables: { id: String(campaignId) } }],
                    awaitRefetchQueries: true,
                });
                const response = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.campaign) === null || _b === void 0 ? void 0 : _b.image) !== null && _c !== void 0 ? _c : {};
                if ('downloadUrl' in response) {
                    setUpdating(false);
                    setData(response);
                }
            }
            catch (err) {
                setUpdating(false);
                throw err;
            }
            finally {
                if (result && ((_e = (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.campaign) === null || _e === void 0 ? void 0 : _e.campaignType) === CampaignType.impactGiving) {
                    yield executeExperience({
                        variables: {
                            filter: { id: (_j = (_h = (_g = (_f = result === null || result === void 0 ? void 0 : result.data) === null || _f === void 0 ? void 0 : _f.campaign) === null || _g === void 0 ? void 0 : _g.experiences) === null || _h === void 0 ? void 0 : _h.items[0]) === null || _j === void 0 ? void 0 : _j.id },
                            data: {
                                experienceHeaderImage: {
                                    connect: payload,
                                },
                            },
                        },
                    });
                }
            }
        });
    }
    return {
        data,
        hasData,
        fetching,
        updating,
        detach,
        attach,
        replace,
    };
}
