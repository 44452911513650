import { useMemo } from 'react';
import { useSortingColumns } from './useSortingColumns';
export const useSortingColumnsQueryVariables = () => {
    const { isSorted, sortedColumn } = useSortingColumns();
    const entries = Object.entries(sortedColumn);
    let column;
    let order;
    if (entries.length > 0) {
        [column, order] = entries[0];
    }
    return useMemo(() => ({
        isSorted,
        sortedColumn: column,
        sortOrder: order,
    }), [isSorted, sortedColumn]);
};
