var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useIntegrationConfigUpdateMutation, useIntegrationsListQuery, useIntegrationConfigListQuery, useIntegrationConfigCreateManyMutation, } from '@vizsla/graphql';
export function useOrganizationIntegrations(organizationId) {
    var _a, _b, _c, _d;
    const { data: integrationsData, loading: gettingIntegrations } = useIntegrationsListQuery();
    const { data: integrationsConfigData, loading: gettingIntegrationsConfig } = useIntegrationConfigListQuery({
        skip: !organizationId,
        variables: { vizslaOrganizationId: organizationId },
    });
    const [integrationConfigCreateManyMutation, { loading: creatingIntegrationsConfig }] = useIntegrationConfigCreateManyMutation({
        refetchQueries: ['IntegrationConfigList'],
        awaitRefetchQueries: true,
    });
    const [integrationConfigUpdateMutation, { loading: updatingIntegrationConfig }] = useIntegrationConfigUpdateMutation({
        refetchQueries: ['IntegrationConfigList'],
        awaitRefetchQueries: true,
    });
    const createIntegrationsConfigMany = useCallback((data) => __awaiter(this, void 0, void 0, function* () {
        var _e;
        try {
            const result = yield integrationConfigCreateManyMutation({
                variables: {
                    data,
                },
            });
            return (_e = result === null || result === void 0 ? void 0 : result.data) === null || _e === void 0 ? void 0 : _e.integrationConfigCreateMany;
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    }), [integrationConfigCreateManyMutation]);
    const updateIntegrationConfig = useCallback((data, id) => __awaiter(this, void 0, void 0, function* () {
        var _f;
        try {
            const result = yield integrationConfigUpdateMutation({
                variables: {
                    id,
                    data,
                },
            });
            return (_f = result === null || result === void 0 ? void 0 : result.data) === null || _f === void 0 ? void 0 : _f.integrationConfigUpdate;
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    }), [integrationConfigUpdateMutation]);
    const integrations = (_b = (_a = integrationsData === null || integrationsData === void 0 ? void 0 : integrationsData.integrationsList) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    const configurations = (_d = (_c = integrationsConfigData === null || integrationsConfigData === void 0 ? void 0 : integrationsConfigData.integrationConfigsList) === null || _c === void 0 ? void 0 : _c.items) !== null && _d !== void 0 ? _d : [];
    return {
        integrations: integrations,
        configurations: configurations,
        loading: gettingIntegrations || gettingIntegrationsConfig,
        updating: updatingIntegrationConfig,
        creating: creatingIntegrationsConfig,
        createIntegrationsConfigMany,
        updateIntegrationConfig,
    };
}
