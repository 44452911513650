import React from 'react';
export const ComparisonFiltersContext = React.createContext({
    comparisonFields: [],
    comparisonFilters: {},
    comparisonFiltersSelected: {},
    isFilterSelected: false,
    onSetFilterValue: () => { },
    onSetFilterValueSelected: () => { },
    onResetFilterValue: () => { },
    onResetFilterValues: () => { },
});
