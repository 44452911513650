var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { generateSlug } from '@vizsla/utils';
import { SPECIAL_SYMBOLS_REG_EXP } from '@vizsla/constants';
import { useDnsRecord } from './useDnsRecord';
import { useCurrentOrganization, useOrganizationById } from '../organization';
const CAMPAIGN_NAME_IS_EXISTED_ERROR_MESSAGE = 'Campaign name already exists';
const CAMPAIGN_NAME_IS_INVALID_ERROR_MESSAGE = 'Campaign name is invalid format';
const CAMPAIGN_NAME_IS_REQUIRED_ERROR_MESSAGE = 'Campaign name is required';
function useDnsRecordValidator() {
    const { getDnsRecordBySlug } = useDnsRecord();
    const previousCampaignName = React.useRef(null);
    const errorMessage = React.useRef(undefined);
    const { organizationId } = useCurrentOrganization();
    const { organization } = useOrganizationById(organizationId);
    const organizationName = organization === null || organization === void 0 ? void 0 : organization.name;
    const validateCampaignName = React.useCallback((campaignName) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        if (campaignName && campaignName !== previousCampaignName.current) {
            errorMessage.current = undefined;
            campaignName = campaignName.replace(/ñ/g, 'n').replace(/[^\w\s]|_/g, '');
            previousCampaignName.current = campaignName;
            const isValidCampaignName = !SPECIAL_SYMBOLS_REG_EXP.test(campaignName);
            if (!isValidCampaignName) {
                errorMessage.current = CAMPAIGN_NAME_IS_INVALID_ERROR_MESSAGE;
            }
            const slug = generateSlug(campaignName);
            const dnsRecord = yield getDnsRecordBySlug(slug);
            if (dnsRecord && ((_b = (_a = dnsRecord.campaign) === null || _a === void 0 ? void 0 : _a.vizslaOrganization) === null || _b === void 0 ? void 0 : _b.name) === organizationName) {
                errorMessage.current = CAMPAIGN_NAME_IS_EXISTED_ERROR_MESSAGE;
            }
            return errorMessage.current;
        }
        if (!campaignName) {
            errorMessage.current = CAMPAIGN_NAME_IS_REQUIRED_ERROR_MESSAGE;
        }
        return errorMessage.current;
    }), [getDnsRecordBySlug, organizationName]);
    return { validateCampaignName };
}
export { useDnsRecordValidator };
