import React from 'react';
export var DateFilterType;
(function (DateFilterType) {
    DateFilterType["options"] = "OPTIONS";
    DateFilterType["custom"] = "CUSTOM";
})(DateFilterType || (DateFilterType = {}));
export var DateFilterRange;
(function (DateFilterRange) {
    DateFilterRange["thisWeek"] = "THIS_WEEK";
    DateFilterRange["thisYear"] = "THIS_YEAR";
    DateFilterRange["lastYear"] = "LAST_YEAR";
    DateFilterRange["last7Days"] = "LAST_7_DAYS";
    DateFilterRange["last30Days"] = "LAST_30_DAYS";
    DateFilterRange["last90Days"] = "LAST_90_DAYS";
    DateFilterRange["last180Days"] = "LAST_180_DAYS";
    DateFilterRange["next30Days"] = "NEXT_30_DAYS";
    DateFilterRange["next90Days"] = "NEXT_90_DAYS";
})(DateFilterRange || (DateFilterRange = {}));
export const DATE_FILTER_OPTIONS = [
    {
        label: 'Next 30 Days',
        value: DateFilterRange.next30Days,
    },
    {
        label: 'Next 90 Days',
        value: DateFilterRange.next90Days,
    },
    {
        label: 'Last 30 Days',
        value: DateFilterRange.last30Days,
    },
    {
        label: 'Last 90 Days',
        value: DateFilterRange.last90Days,
    },
    {
        label: 'This year',
        value: DateFilterRange.thisYear,
    },
    {
        label: 'Last year',
        value: DateFilterRange.lastYear,
    },
];
export const CustomFiltersContext = React.createContext({
    filters: [],
    filterValues: {},
    filterValuesReport: {},
    hasFilterValues: false,
    onSetFilterValue: () => { },
    onSetFilterValueReport: () => { },
    onResetFilterValue: () => { },
    onResetFilterValues: () => { },
    getFilterKeyFromSettings: () => '',
});
