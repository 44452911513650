var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CustomFiltersContext, } from './CustomFilterContext';
function getFilterKeyFromSettings(filterSettings) {
    if (!filterSettings) {
        return '';
    }
    const { type } = filterSettings;
    switch (type) {
        case 'checkbox': {
            return filterSettings.filterValueName;
        }
        case 'date': {
            return filterSettings.dateFilterKey;
        }
        case 'range-count': {
            return filterSettings.filterValueName;
        }
    }
    return '';
}
const CustomFiltersProvider = ({ children, customFilters, }) => {
    const [filterValues, setFilterValues] = React.useState({});
    const [filterValuesReport, setFilterReportValues] = React.useState({});
    const onSetFilterValue = (filterKey, values) => {
        setFilterValues(filters => (Object.assign(Object.assign({}, filters), { [filterKey]: values })));
    };
    const onSetFilterValueReport = (filterKey, values) => {
        setFilterReportValues(filters => (Object.assign(Object.assign({}, filters), { [filterKey]: values })));
    };
    const checkEmptyFilters = () => {
        const keys = Object.keys(filterValues) || [];
        const filtersNonEmpty = keys.filter(key => Object.keys(filterValues[key]).length !== 0);
        return filtersNonEmpty.length > 0;
    };
    const hasFilterValues = checkEmptyFilters();
    const onResetFilterValue = (filterKey) => {
        setFilterValues(filters => {
            const _a = filters, _b = filterKey, deletedFilter = _a[_b], state = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            return state;
        });
    };
    const onResetFilterValues = () => {
        setFilterValues({});
    };
    return (_jsx(CustomFiltersContext.Provider, Object.assign({ value: {
            filters: customFilters,
            filterValues,
            filterValuesReport,
            hasFilterValues,
            onSetFilterValue,
            onResetFilterValue,
            onResetFilterValues,
            getFilterKeyFromSettings,
            onSetFilterValueReport,
        } }, { children: children })));
};
CustomFiltersProvider.displayName = 'CustomFiltersProvider';
export { CustomFiltersProvider };
