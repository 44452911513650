import { useCheckFacebookFundraisingQuery } from '@vizsla/graphql';
/**
 * Check the connection between the current user and the **Facebook Account** credentials, expiration of the credentials and their fundraising page status.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-581
 */
export function useCheckFacebookFundraising(attendee) {
    var _a, _b, _c, _d;
    const { data: response, loading } = useCheckFacebookFundraisingQuery({
        skip: !attendee,
        variables: {
            attendee: { id: attendee === null || attendee === void 0 ? void 0 : attendee.id },
        },
    });
    const hasCredentials = (_a = response === null || response === void 0 ? void 0 : response.connection.hasCredentials) !== null && _a !== void 0 ? _a : false;
    const hasCredentialsExpired = (_b = response === null || response === void 0 ? void 0 : response.connection.hasCredentialsExpired) !== null && _b !== void 0 ? _b : false;
    const hasFundraisingPage = (_c = response === null || response === void 0 ? void 0 : response.connection.hasFundraisingPage) !== null && _c !== void 0 ? _c : false;
    const fundraisingPage = (_d = response === null || response === void 0 ? void 0 : response.connection.fundraisingPage) !== null && _d !== void 0 ? _d : undefined;
    return {
        /** Has the current attendee credentials? */
        hasCredentials,
        /** Has the current attendee credentials expired? */
        hasCredentialsExpired,
        /** Has the current attendee a fundraising page created? */
        hasFundraisingPage,
        /** Returns the current attendee fundraising page on Facebook (when is created). */
        fundraisingPage,
        loading,
    };
}
