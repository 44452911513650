import { useMemo } from 'react';
import { useGlobalOrganizationListQuery } from '@vizsla/graphql';
/** List all the global organizations available. */
export function useGlobalOrganizationList(options) {
    var _a, _b;
    const { data: response, loading } = useGlobalOrganizationListQuery(options);
    const data = (_b = (_a = response === null || response === void 0 ? void 0 : response.globalOrganizations) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    return {
        data,
        loading,
    };
}
/** List all the global organizations available matched with the {@link SelectFieldOption} interface. */
export function useGlobalOrganizationSelectOptions(options) {
    const { data: response, loading } = useGlobalOrganizationList(options);
    const data = useMemo(() => {
        return response.map(option => {
            return {
                label: option.name,
                value: option.id,
            };
        });
    }, [response]);
    return {
        data,
        loading,
    };
}
