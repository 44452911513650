var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
export function useTransformTransactions(transactions) {
    const [transformedTransactions, setTransformedTransactions] = useState([]);
    const [loadingTransform, setLoadingTransform] = useState(true);
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            setLoadingTransform(true);
            setTransformedTransactions(transactions);
            setLoadingTransform(false);
        }))();
    }, [transactions]);
    return [transformedTransactions, loadingTransform];
}
