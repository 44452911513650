import { useMemo } from 'react';
import { createFilterBuilder, useReportFoldersQuery, } from '@vizsla/graphql';
import { useCurrentOrganization } from '../organization';
/**
 * List all report folders for the current organization.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-2103
 */
export function useCurrentOrganizationReportFolders(options) {
    var _a, _b;
    const { organization, organizationIsLoading: organizationLoading } = useCurrentOrganization();
    const filter = useMemo(() => {
        var _a;
        const filter = createFilterBuilder((_a = options === null || options === void 0 ? void 0 : options.variables) === null || _a === void 0 ? void 0 : _a.filter);
        if (organization) {
            filter.and({ organization: { id: { equals: organization.id } } });
        }
        return filter.build();
    }, [options, organization]);
    const { data: response, loading: responseLoading } = useReportFoldersQuery(Object.assign(Object.assign({}, options), { skip: (options === null || options === void 0 ? void 0 : options.skip) || organizationLoading || !organization, variables: Object.assign(Object.assign({}, options === null || options === void 0 ? void 0 : options.variables), { filter }) }));
    const data = (_a = response === null || response === void 0 ? void 0 : response.folders.items) !== null && _a !== void 0 ? _a : [];
    const count = (_b = response === null || response === void 0 ? void 0 : response.folders.count) !== null && _b !== void 0 ? _b : 0;
    const loading = organizationLoading || responseLoading;
    return {
        data,
        count,
        loading,
    };
}
