import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
export const useMediaBreakpoints = ({ custom } = {}) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));
    const isXl = useMediaQuery(theme.breakpoints.down('xl'));
    const isCustomDeviceDown = useMediaQuery(theme.breakpoints.down(custom !== null && custom !== void 0 ? custom : 0));
    const isCustomDeviceUp = useMediaQuery(theme.breakpoints.up(custom !== null && custom !== void 0 ? custom : 0));
    return {
        isXs,
        isSm,
        isMd,
        isLg,
        isXl,
        isCustomDeviceDown,
        isCustomDeviceUp, // For custom devices above the size
    };
};
