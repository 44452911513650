import { useMemo } from 'react';
import { useInboxMessagesQuery } from '@vizsla/graphql';
import { InboxMessageFilterOption, InboxTypeFilterOption } from '@vizsla/types';
import { useInboxContext } from '../context';
import { useInboxFilters } from './UseInboxFilters';
import { useCurrentUserQuery } from './useCurrentUserQuery';
const transformFilterOptionsIntoInboxMessageFilter = (filterOptions) => {
    return filterOptions
        .map(filterOption => {
        if (filterOption in InboxTypeFilterOption)
            return null;
        if (filterOption === InboxMessageFilterOption.All)
            return null;
        if (filterOption === InboxMessageFilterOption.Read) {
            return {
                AND: [{ readBy: { none: { is_self: false } } }, { readBy: { some: { is_self: true } } }],
            };
        }
        if (filterOption === InboxMessageFilterOption.Unread) {
            // The filter should have a clause that checks if the message is not read by the current user
            // But the filter none has a bug that it does not work with nested filters
            // for now we are using the js to filter the messages that are not read by the current user
            return { createdBy: { is_self: false } };
        }
        if (filterOption === InboxMessageFilterOption.Sent) {
            return { createdBy: { is_self: true } };
        }
        return null;
    })
        .filter(Boolean);
};
export function useInboxMessages() {
    const { userId } = useCurrentUserQuery();
    const { selectedInbox, selectedInboxIsDraft } = useInboxContext();
    const { filters } = useInboxFilters();
    const inboxMessageFilters = useMemo(() => {
        const messageFilters = transformFilterOptionsIntoInboxMessageFilter(filters);
        if (messageFilters.length === 0)
            return {};
        return { OR: messageFilters };
    }, [filters]);
    const skipQueryWhenInboxIsDraft = selectedInboxIsDraft || !(selectedInbox === null || selectedInbox === void 0 ? void 0 : selectedInbox.id);
    const { data: response, error, loading, refetch, } = useInboxMessagesQuery({
        skip: skipQueryWhenInboxIsDraft,
        variables: {
            filter: Object.assign({ inbox: { id: { equals: selectedInbox === null || selectedInbox === void 0 ? void 0 : selectedInbox.id } } }, inboxMessageFilters),
        },
        fetchPolicy: 'network-only',
    });
    const data = useMemo(() => {
        var _a;
        const messages = ((_a = response === null || response === void 0 ? void 0 : response.inboxMessages.items) !== null && _a !== void 0 ? _a : []);
        if (filters.includes(InboxMessageFilterOption.Unread)) {
            return messages.filter(m => {
                var _a, _b;
                const readByItems = (_b = (_a = m.readBy) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
                const readByCurrentUser = readByItems.some(r => r.id === userId);
                return !readByCurrentUser;
            });
        }
        return messages;
    }, [response]);
    return { data, error, loading, refetch };
}
