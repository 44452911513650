import React from 'react';
export const SortingColumnsContext = React.createContext({
    columns: [],
    sortedColumn: {},
    columnSelected: '',
    isSorted: false,
    checkColumnSorted: () => false,
    onSetSortColumn: () => { },
    onSelectColumn: () => { },
    onResetSortColumn: () => { },
});
