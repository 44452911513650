import { useMemo } from 'react';
import { useSelectedFields } from './useSelectedFields';
export function useSelectedFieldsQueryVariables() {
    const { fields, selectedFields, hasSelectedFields, checkFieldSelected, onSetSelectedValue, onResetSelectedValues, onSelectedAllValues, } = useSelectedFields();
    return useMemo(() => {
        return {
            fields,
            selectedFields,
            hasSelectedFields,
            checkFieldSelected,
            onSetSelectedValue,
            onResetSelectedValues,
            onSelectedAllValues,
        };
    }, [fields, selectedFields, hasSelectedFields]);
}
