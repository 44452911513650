import React from 'react';
export const SelectedFieldsContext = React.createContext({
    fields: [],
    selectedFields: {},
    hasSelectedFields: false,
    checkFieldSelected: () => false,
    onSetSelectedValue: () => { },
    onResetSelectedValues: () => { },
    onSelectedAllValues: () => { },
});
