var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useCheckoutMutation } from '@vizsla/graphql';
import { createCartStore } from '../cart';
export const useShoppingCartState = createCartStore();
export function useShoppingCart() {
    const [executeCheckout] = useCheckoutMutation();
    const { items } = useShoppingCartState();
    /** Send the `checkoutShoppingCart` with the actual shopping cart. */
    return useCallback((options) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const response = yield executeCheckout({
            variables: {
                experience: options.experience,
                parentOrder: options.parentOrder,
                cart: items,
                billingInfo: options.billingInfo,
            },
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.checkout;
    }), [items, executeCheckout]);
}
