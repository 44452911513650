var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useMatch } from 'react-router-dom';
import { useCampaignPageTemplateUpdateMutation, useExperienceLandingQuery, useTemplateStructureQuery, } from '@vizsla/graphql';
import { TemplateType } from '@vizsla/types';
import { ExperienceLandingContext } from './ExperienceLandingContext';
// can't use @vizsla/hooks in @vizsla/provider, it makes dependency loop
const useExperienceById = (id) => {
    const { data, loading } = useExperienceLandingQuery({
        variables: {
            id,
        },
    });
    return { experience: data === null || data === void 0 ? void 0 : data.experience, loading };
};
// TODO: move to packages/constants
const EDITOR_ROUTE_NAME = {
    EXPERIENCE: '/editor/:id/experience',
    CAMPAIGN: '/editor/:id/experience',
    INDIVIDUAL_FUNDRAISER: '/editor/:id/individual-fundraiser',
    TEAM_FUNDRAISER: '/editor/:id/team-fundraiser',
};
const ExperienceLandingProvider = ({ children, experienceId, campaignId = '', templateType, }) => {
    const isEditor = Boolean(useMatch(EDITOR_ROUTE_NAME[templateType]));
    const [updateTemplateStructure, { loading: updating }] = useCampaignPageTemplateUpdateMutation();
    const { experience, loading: experienceLoading } = useExperienceById(experienceId);
    const { data: templateStructureData, loading: templateStructureDataLoading } = useTemplateStructureQuery({
        variables: { campaignId, templateType: TemplateType[templateType] },
        skip: !campaignId,
    });
    const templateStructure = templateStructureData === null || templateStructureData === void 0 ? void 0 : templateStructureData.templateStructuresList.items[0];
    const save = React.useCallback((landingState) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const structure = {
                appSchema: { nodes: landingState.nodes, theme: landingState.theme },
            };
            yield updateTemplateStructure({
                variables: {
                    data: {
                        id: templateStructure === null || templateStructure === void 0 ? void 0 : templateStructure.id,
                        structure,
                    },
                },
            });
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    }), [templateStructure === null || templateStructure === void 0 ? void 0 : templateStructure.id, updateTemplateStructure]);
    const loading = experienceLoading || templateStructureDataLoading;
    return (_jsx(ExperienceLandingContext.Provider, Object.assign({ value: {
            experience,
            loading,
            updating,
            save,
            templateStructure,
            isEditor,
        } }, { children: children })));
};
export { ExperienceLandingProvider };
