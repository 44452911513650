var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import lookup from '@photostructure/tz-lookup';
import { TIME_MAP_ZONE } from '@vizsla/utils';
import { useGetOrganizationByExperienceQuery, useSaveExperienceTimeZoneMutation, } from '@vizsla/graphql';
export const useGetTimeZoneByAddress = ({ experienceId }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [saveTimeZone] = useSaveExperienceTimeZoneMutation();
    const organizationData = useGetOrganizationByExperienceQuery({
        variables: {
            id: experienceId,
        },
    });
    const organizationTimeZone = (_e = (_d = (_c = (_b = (_a = organizationData === null || organizationData === void 0 ? void 0 : organizationData.data) === null || _a === void 0 ? void 0 : _a.experience) === null || _b === void 0 ? void 0 : _b.campaign) === null || _c === void 0 ? void 0 : _c.vizslaOrganization) === null || _d === void 0 ? void 0 : _d.timeZone) !== null && _e !== void 0 ? _e : 'UTC';
    const determineAndSaveTimeZone = ({ address, setTimeZone, }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!address && organizationTimeZone) {
            setTimeZone(TIME_MAP_ZONE[organizationTimeZone]);
            saveTimeZone({
                variables: {
                    data: {
                        id: experienceId,
                        timeZone: TIME_MAP_ZONE[organizationTimeZone],
                    },
                },
            });
            return;
        }
        try {
            if (!address)
                return;
            const response = yield fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&addressdetails=1&limit=1`);
            const data = yield response.json();
            if (data.length > 0) {
                const { lat, lon } = data[0];
                let attempts = 0;
                let foundTimeZone = null;
                while (attempts < 5 && !foundTimeZone) {
                    try {
                        const timeZone = lookup(Number(lat), Number(lon) + attempts);
                        if (timeZone && timeZone.length > 0) {
                            foundTimeZone = timeZone[0];
                            setTimeZone(foundTimeZone);
                            saveTimeZone({
                                variables: {
                                    data: {
                                        id: experienceId,
                                        timeZone: foundTimeZone,
                                    },
                                },
                            });
                            break;
                        }
                    }
                    catch (error) {
                        console.error(`Error finding time zone on attempt ${attempts}:`, error);
                    }
                    attempts += 1;
                }
                if (!foundTimeZone && organizationTimeZone) {
                    setTimeZone(TIME_MAP_ZONE[organizationTimeZone]);
                    saveTimeZone({
                        variables: {
                            data: {
                                id: experienceId,
                                timeZone: TIME_MAP_ZONE[organizationTimeZone],
                            },
                        },
                    });
                }
            }
            else {
                console.error('No coordinates found for the provided address.');
                if (organizationTimeZone) {
                    setTimeZone(TIME_MAP_ZONE[organizationTimeZone]);
                    saveTimeZone({
                        variables: {
                            data: {
                                id: experienceId,
                                timeZone: TIME_MAP_ZONE[organizationTimeZone],
                            },
                        },
                    });
                }
            }
        }
        catch (error) {
            console.error('Error fetching coordinates:', error);
            if (organizationTimeZone) {
                setTimeZone(TIME_MAP_ZONE[organizationTimeZone]);
                saveTimeZone({
                    variables: {
                        data: {
                            id: experienceId,
                            timeZone: TIME_MAP_ZONE[organizationTimeZone],
                        },
                    },
                });
            }
        }
    });
    const determineTimeZone = ({ address }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&addressdetails=1&limit=1`);
            const data = yield response.json();
            if (data.length > 0) {
                const { lat, lon } = data[0];
                let attempts = 0;
                let foundTimeZone = null;
                while (attempts < 5 && !foundTimeZone) {
                    try {
                        const timeZone = lookup(Number(lat), Number(lon) + attempts);
                        if (timeZone && timeZone.length > 0) {
                            foundTimeZone = timeZone;
                            break;
                        }
                    }
                    catch (error) {
                        console.error(`Error finding time zone on attempt ${attempts}:`, error);
                        return {
                            timeZone: null,
                        };
                    }
                    attempts += 1;
                }
                return {
                    timeZone: foundTimeZone,
                };
            }
            else {
                console.error('No coordinates found for the provided address.');
                return {
                    timeZone: null,
                };
            }
        }
        catch (error) {
            console.error('Error fetching coordinates:', error);
            return {
                timeZone: null,
            };
        }
    });
    return {
        determineAndSaveTimeZone,
        determineTimeZone,
        venues: (_g = (_f = organizationData === null || organizationData === void 0 ? void 0 : organizationData.data) === null || _f === void 0 ? void 0 : _f.experience) === null || _g === void 0 ? void 0 : _g.venues,
    };
};
