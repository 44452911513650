import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SortingColumnsContext } from './SortingColumnsContext';
const SortingColumnsProvider = ({ children, columns }) => {
    const [sortedColumn, setSortedColumn] = React.useState({});
    const [columnSelected, setColumnSelected] = React.useState('');
    const onSetSortColumn = (column, order) => {
        setSortedColumn(() => {
            const updatedFields = {};
            updatedFields[column] = order;
            return updatedFields;
        });
    };
    const onSelectColumn = (columnName) => {
        setColumnSelected(columnName);
    };
    const checkIfSorted = () => {
        const keys = Object.keys(sortedColumn) || [];
        const fieldsNonEmpty = keys.filter(key => Object.keys(sortedColumn[key]).length !== 0);
        return fieldsNonEmpty.length > 0;
    };
    const checkColumnSorted = (filterKey) => {
        const selectedColumn = sortedColumn[filterKey];
        return selectedColumn && (selectedColumn === null || selectedColumn === void 0 ? void 0 : selectedColumn.length) > 0;
    };
    const onResetSortColumn = () => {
        setSortedColumn({});
    };
    const isSorted = checkIfSorted();
    return (_jsx(SortingColumnsContext.Provider, Object.assign({ value: {
            columns,
            sortedColumn,
            columnSelected,
            isSorted,
            checkColumnSorted,
            onSetSortColumn,
            onSelectColumn,
            onResetSortColumn,
        } }, { children: children })));
};
SortingColumnsProvider.displayName = 'SortingColumnsProvider';
export { SortingColumnsProvider };
