var CartItemType;
(function (CartItemType) {
    CartItemType["Registration"] = "registration";
    CartItemType["Discount"] = "discount";
    CartItemType["Donation"] = "donation";
    CartItemType["Asset"] = "asset";
    CartItemType["SwagBag"] = "swagBag";
    CartItemType["VizslaFee"] = "vizslaFee";
    CartItemType["PaymentFee"] = "paymentFee";
    CartItemType["RoundUp"] = "roundup";
    CartItemType["Taxes"] = "tax";
})(CartItemType || (CartItemType = {}));
/**
 * Selects all items of a given type
 * @param {CartStore<ShoppingCartData>} state - The cart state
 * @param {CartItemType} type - The type of item to select
 */
export const selectAllByType = (state, type) => {
    return state.items.filter(item => item.type === type);
};
/**
 * Selects all items of type registration
 * @param {CartStore<ShoppingCartData>} state
 * @returns {CartItem<ShoppingCartRegistrationData>[]}
 *
 * @example
 * const registrations = useS(selectAllRegistrations);
 */
export const selectAllRegistrations = (state) => {
    return selectAllByType(state, CartItemType.Registration);
};
export const selectAllDiscounts = (state) => {
    return selectAllByType(state, CartItemType.Discount);
};
export const selectAllDonations = (state) => {
    return selectAllByType(state, CartItemType.Donation);
};
export const selectAllAssets = (state) => {
    return selectAllByType(state, CartItemType.Asset);
};
export const selectAllSwagBags = (state) => {
    return selectAllByType(state, CartItemType.SwagBag);
};
export const selectAllVizslaFee = (state) => {
    return selectAllByType(state, CartItemType.VizslaFee);
};
export const selectAllPaymentFee = (state) => {
    return selectAllByType(state, CartItemType.PaymentFee);
};
export const selectAllRoundUp = (state) => {
    return selectAllByType(state, CartItemType.RoundUp);
};
export const selectAllTaxes = (state) => {
    return selectAllByType(state, CartItemType.Taxes);
};
