var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { InboxesListDocument, useInboxCreateMutation, } from '@vizsla/graphql';
export function useCreateInboxMutation() {
    const [createInbox, { loading, error }] = useInboxCreateMutation();
    const mutation = useCallback((data) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const response = yield createInbox({
            variables: { data },
            refetchQueries: [InboxesListDocument],
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.createInbox;
    }), [createInbox]);
    return { mutation, loading, error };
}
