import { useState, useMemo } from 'react';
export const useCustomPaginationTable = (initialPage = 0, initialRowsPerPage = 5) => {
    const [page, setPage] = useState(initialPage);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const { skip, first } = useMemo(() => ({
        skip: page * rowsPerPage,
        first: rowsPerPage,
    }), [page, rowsPerPage]);
    const paginate = (data, first, skip) => {
        return data.slice(skip, skip + first);
    };
    return { paginate, skip, first, setPage, setRowsPerPage, page, rowsPerPage };
};
