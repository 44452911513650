var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useDonorCommitmentUpdateMutation, useDonorCommitmentsListQuery, useDonorCommitmentCreateMutation, } from '@vizsla/graphql';
export function useDonorCommitment(organizationId) {
    var _a, _b;
    const { data: donorCommitmentData, loading: loadingDonorCommitmentsList } = useDonorCommitmentsListQuery({
        variables: {
            vizlaOrganizationID: organizationId,
        },
    });
    const [DonorCommitmentCreateMutation, { loading: creatingDonorCommitment }] = useDonorCommitmentCreateMutation({
        refetchQueries: ['DonorCommitmentList', 'GetCommitments'],
        awaitRefetchQueries: true,
    });
    const [DonorCommitmentUpdateMutation, { loading: updatingDonorCommitment }] = useDonorCommitmentUpdateMutation({
        refetchQueries: ['DonorCommitmentList', 'GetCommitments'],
        awaitRefetchQueries: true,
    });
    const createDonorCommitment = useCallback((data) => __awaiter(this, void 0, void 0, function* () {
        var _c;
        try {
            const result = yield DonorCommitmentCreateMutation({
                variables: {
                    data,
                },
            });
            return (_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.donorCommitmentCreate;
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    }), [DonorCommitmentCreateMutation]);
    const updateDonorCommitment = useCallback((data, filter) => __awaiter(this, void 0, void 0, function* () {
        var _d;
        try {
            const result = yield DonorCommitmentUpdateMutation({
                variables: {
                    data,
                    filter,
                },
            });
            return (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.donorCommitmentUpdate;
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    }), [DonorCommitmentUpdateMutation]);
    const donorCommitmentsList = (_b = (_a = donorCommitmentData === null || donorCommitmentData === void 0 ? void 0 : donorCommitmentData.donorCommitmentsList) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    return {
        donorCommitmentsList,
        loadingDonorCommitmentsList,
        updatingDonorCommitment,
        creatingDonorCommitment,
        createDonorCommitment,
        updateDonorCommitment,
    };
}
