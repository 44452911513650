import { useParams } from 'react-router-dom';
import { getUserSystemRoles } from '@vizsla/utils';
import { Application, SystemRoles } from '@vizsla/types';
import { useOrganizationById } from './useOrganizationById';
import { useCurrentUser } from '../user';
export function useCurrentOrganization() {
    var _a, _b, _c, _d, _e, _f;
    const { user, loading: userIsLoading } = useCurrentUser();
    const { organizationId: id } = useParams();
    // todo: implement different Organizations and different Roles support
    // right now the first organizationStaff record defines which organization user can look
    const userRoles = getUserSystemRoles(user !== null && user !== void 0 ? user : {});
    const isCollieAdmin = userRoles.includes(SystemRoles.VizslaAdministrator);
    let organizationId = null;
    if (id !== undefined) {
        organizationId = id;
    }
    else {
        organizationId =
            isCollieAdmin && (user === null || user === void 0 ? void 0 : user.currentApplication) === Application.CollieOrgClient
                ? (_b = (_a = user.currentOrganization) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : undefined
                : (_f = (_e = (_d = (_c = user === null || user === void 0 ? void 0 : user.vizslaOrganizationStaff) === null || _c === void 0 ? void 0 : _c.items[0]) === null || _d === void 0 ? void 0 : _d.vizslaOrganization) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : undefined;
    }
    const { organization: data, organizationIsLoading: organizationLoading } = useOrganizationById(organizationId);
    const loading = userIsLoading || organizationLoading;
    return {
        data,
        loading,
        organizationId,
        organization: data,
        organizationIsLoading: userIsLoading || organizationLoading,
    };
}
