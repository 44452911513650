/* eslint-disable no-console */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Application, AppClient } from '@vizsla/types';
import { useClient, useUserCurrentApp, useAppAuth } from '..';
export const useClientRedirect = () => {
    const { client, clientsUrl } = useClient();
    const { loading: isAppLoading, currentApp } = useUserCurrentApp();
    const { isAuthenticated } = useAppAuth();
    const navigate = useNavigate();
    React.useEffect(() => {
        let redirectUrl;
        if (isAuthenticated && currentApp !== null) {
            if (!isAppLoading && currentApp) {
                const isAdminCurrentApp = currentApp === Application.OrganizationAdminClient ||
                    currentApp === Application.VizslaAdminClient ||
                    currentApp === Application.CampaignAdminClient ||
                    currentApp === Application.CampaignStaffClient ||
                    currentApp === Application.FinancialClient ||
                    currentApp === Application.CollieOrgClient;
                const isCheckinCurrentApp = currentApp === Application.CheckInClient;
                if (client === AppClient.AdminClient && isAdminCurrentApp) {
                    redirectUrl = clientsUrl.adminClientUrl;
                }
                if (client === AppClient.CheckInClient && isCheckinCurrentApp) {
                    redirectUrl = clientsUrl.checkinClientUrl;
                }
            }
        }
        if (redirectUrl && currentApp !== null) {
            navigate(redirectUrl);
        }
    }, [client, clientsUrl, isAppLoading, currentApp, isAuthenticated]);
};
