import { useMemo } from 'react';
import { useImpactedOrganizationsQuery, } from '@vizsla/graphql';
import { useCurrentUser } from '../index';
import { useOrganizationImpactFilters } from './useOrganizationImpactFilters';
const buildFilters = (userID, dateRange) => {
    var _a, _b;
    return [
        {
            campaigns: {
                some: {
                    experiences: {
                        some: {
                            OR: [
                                {
                                    attendees: {
                                        some: {
                                            user: {
                                                id: {
                                                    equals: userID,
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    allocatedDonations: {
                                        some: {
                                            donationFulfillment: {
                                                donor: {
                                                    id: {
                                                        equals: userID,
                                                    },
                                                },
                                            },
                                            AND: [
                                                ...(dateRange.startDate
                                                    ? [{ createdAt: { gte: (_a = dateRange.startDate) === null || _a === void 0 ? void 0 : _a.gte } }]
                                                    : []),
                                                ...(dateRange.endDate
                                                    ? [{ createdAt: { lte: (_b = dateRange.endDate) === null || _b === void 0 ? void 0 : _b.lte } }]
                                                    : []),
                                            ],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        },
    ];
};
export function useImpactedOrganizations() {
    const { user } = useCurrentUser();
    const { filters } = useOrganizationImpactFilters();
    const { error, data: response, loading, } = useImpactedOrganizationsQuery({
        skip: !(user === null || user === void 0 ? void 0 : user.id),
        variables: {
            filters: (user === null || user === void 0 ? void 0 : user.id) ? buildFilters(user.id, filters) : {},
        },
    });
    const data = useMemo(() => { var _a, _b; return (_b = (_a = response === null || response === void 0 ? void 0 : response.organizations) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []; }, [response]);
    return { data, error, loading };
}
