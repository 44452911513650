import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ComparisonFiltersContext, } from './ComparisonFiltersContext';
const ComparisonFiltersProvider = ({ children, comparisonFields, }) => {
    const [comparisonFilters, setComparisonFilters] = React.useState({});
    const [comparisonFiltersSelected, setComparisonFiltersSelected] = React.useState({});
    const onSetFilterValue = (filterKey, values) => {
        setComparisonFilters(filters => (Object.assign(Object.assign({}, filters), { [filterKey]: values })));
    };
    const onSetFilterValueSelected = (filterKey, values) => {
        setComparisonFiltersSelected(filters => (Object.assign(Object.assign({}, filters), { [filterKey]: values })));
    };
    const checkEmptyFields = () => {
        const keys = Object.keys(comparisonFilters) || [];
        const fieldsNonEmpty = keys.filter(key => comparisonFilters[key] && Object.keys(comparisonFilters[key]).length !== 0);
        return fieldsNonEmpty.length > 0;
    };
    const isFilterSelected = checkEmptyFields();
    const onResetFilterValue = (id) => { };
    const onResetFilterValues = () => { };
    return (_jsx(ComparisonFiltersContext.Provider, Object.assign({ value: {
            comparisonFields,
            comparisonFilters,
            comparisonFiltersSelected,
            isFilterSelected,
            onSetFilterValue,
            onSetFilterValueSelected,
            onResetFilterValue,
            onResetFilterValues,
        } }, { children: children })));
};
ComparisonFiltersProvider.displayName = 'ComparisonFiltersProvider';
export { ComparisonFiltersProvider };
