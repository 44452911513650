import { create } from 'zustand';
import { nanoid } from 'nanoid';
/**
 * Generates a instance of tools to create a reactive cart ready-to-use.
 * @example
 * ```tsx
 * // useCart.ts
 *
 * export const useCart = createCartStore()
 *
 * // CartResume.tsx
 *
 * function CartResume() {
 *  const { items, subtotal } = useCart()
 *
 *  const total = useMemo(() => subtotal(), [items])
 *
 *  return (
 *    <div>
 *      {items.map(item => (
 *        <div>
 *          <span>{item.name}</span>
 *          <span>${item.price}</span>
 *        </div>
 *      ))}
 *
 *      <span>Total: {total}</span>
 *    </div>
 *  )
 * }
 * ```
 */
export function createCartStore() {
    const INITIAL_STATE = {
        items: [],
    };
    return create((setState, getState) => (Object.assign(Object.assign({}, INITIAL_STATE), { add(item) {
            const id = nanoid();
            const data = Object.assign(Object.assign({}, item), { id });
            setState(prev => {
                return { items: [...prev.items, data] };
            });
            return data;
        },
        edit(item) {
            const state = getState();
            const actual = state.items.find(i => i.id === item.id);
            if (actual) {
                const data = Object.assign(Object.assign({}, actual), item);
                const items = state.items.map(prev => {
                    if (prev.id === data.id) {
                        // Replace the same ID with the merged data.
                        return data;
                    }
                    // Keep the same data for the others.
                    return prev;
                });
                setState({ items });
                return data;
            }
            return undefined;
        },
        set(id, data) {
            const state = getState();
            const item = Object.assign({ id }, data);
            const exists = state.items.some(curr => curr.id === id);
            if (exists) {
                const items = state.items.map(prev => {
                    if (prev.id === id) {
                        // Replace the same ID but with new data.
                        return item;
                    }
                    // Keep the same data for the others.
                    return prev;
                });
                setState({ items });
                return item;
            }
            setState(prev => {
                // Add the new item to the state because it does not exists.
                return { items: [...prev.items, item] };
            });
            return item;
        },
        setMany(items) {
            const state = getState();
            const cart = [...state.items];
            /* eslint guard-for-in: "off" */
            for (const index in cart) {
                const current = cart[index];
                const newest = items.find(i => i.id === current.id);
                if (newest) {
                    cart[index] = newest;
                }
            }
            setState({ items: cart });
            return cart;
        },
        editMany(items) {
            const state = getState();
            const cart = [...state.items];
            /* eslint guard-for-in: "off" */
            for (const index in cart) {
                const current = cart[index];
                const newest = items.find(i => i.id === current.id);
                if (newest) {
                    cart[index] = Object.assign(Object.assign({}, current), newest);
                }
            }
            setState({ items: cart });
            return cart;
        },
        remove(item) {
            const state = getState();
            const actual = state.items.find(curr => curr.id === item.id);
            const items = state.items.filter(curr => curr.id !== item.id);
            setState({ items });
            return actual;
        },
        removeMany(items) {
            const state = getState();
            const excluded = new Set(items.map(item => item.id));
            const included = state.items.filter(curr => excluded.has(curr.id) === false);
            setState({ items: included });
        },
        total() {
            const state = getState();
            return state.items.reduce((acc, item) => acc + item.price, 0);
        },
        clean() {
            setState(Object.assign({}, INITIAL_STATE));
        } })));
}
