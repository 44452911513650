var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DateTime } from 'luxon';
import { useConnectAttendeeFacebookAccountMutation, useCreateFacebookAccountRedirectMutation, } from '@vizsla/graphql';
/**
 * Allow to interact with the **Facebook Login API** using a custom flow.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-581
 * @documentation https://developers.facebook.com/docs/facebook-login/guides/advanced/manual-flow
 */
export function useFacebookOAuth() {
    const [createSignIn] = useCreateFacebookAccountRedirectMutation();
    const [connectAccount] = useConnectAttendeeFacebookAccountMutation();
    return {
        /**
         * Allow to redirect the user to the **Facebook Sign-In** page to start a session and be redirected using the `callbackURL` with a token.
         * @documentation https://developers.facebook.com/docs/facebook-login/guides/advanced/manual-flow
         */
        openSignIn(options) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield createSignIn({
                    variables: {
                        attendee: options.attendee,
                        redirectUri: `${options.callbackURL}`,
                        permissions: options.permissions,
                    },
                });
                if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.url)) {
                    throw new Error(`No response URL was found`);
                }
                window.location.assign(response.data.url);
            });
        },
        /**
         * Connect the given `attendee` with the `credentials`.
         * @ticket https://8base-dev.atlassian.net/browse/VSL-581
         */
        connectAcount(credentials) {
            return __awaiter(this, void 0, void 0, function* () {
                // Get the current timestamp in milliseconds
                const now = DateTime.now();
                // Convert expiresIn from seconds to milliseconds
                const expiresInMilliseconds = credentials.expiresIn * 1000;
                // Calculate the expiration timestamp
                const expirationDate = now.plus({ milliseconds: expiresInMilliseconds });
                console.log('🚀 ~ connectAcount ~ expirationDate:', expirationDate);
                // Format the DateTime object as an ISO 8601 string
                const expiredAt = expirationDate.toISO();
                console.log('🚀 ~ connectAcount ~ expiredAt:', expiredAt);
                const response = yield connectAccount({
                    variables: {
                        attendee: { id: credentials.attendee.id },
                        token: credentials.accessToken,
                        expiredAt,
                    },
                });
                return response.data;
            });
        },
        /**
         * Extract from the current URL the variables send by Facebook with the token and some other states.
         * @throws When the `hash` does not have any value.
         * @throws When the response was negative and Facebook redirects with an error.
         * @throws When the response does not contains the `access_token`.
         * @param url Current URL of the page where you get redirect from the **Facebook Sign-In**.
         * @documentation https://developers.facebook.com/docs/facebook-login/guides/advanced/manual-flow
         */
        extractCredentialsFromRedirect(url) {
            return __awaiter(this, void 0, void 0, function* () {
                const error = url.searchParams.get('error');
                if (error) {
                    const message = url.searchParams.get('error_description');
                    throw new Error(message !== null && message !== void 0 ? message : undefined);
                }
                const accessToken = url.searchParams.get('access_token');
                if (!accessToken) {
                    throw new Error(`No "access_token" was found`);
                }
                const serializedState = url.searchParams.get('state');
                if (!serializedState) {
                    throw new Error(`No "state" was found`);
                }
                const state = deserializeFacebookState(serializedState);
                if (!state.attendee) {
                    throw new Error(`No "attendee" was found in the state`);
                }
                const data = {
                    attendee: { id: state.attendee },
                    accessToken,
                    accessExpirationTime: 0,
                    expiresIn: 0,
                    grantedScopes: [],
                    deniedScopes: [],
                };
                const accessExpirationTime = url.searchParams.get('data_access_expiration_time');
                const expiresIn = url.searchParams.get('expires_in');
                const grantedScopes = url.searchParams.get('granted_scopes');
                const deniedScopes = url.searchParams.get('denied_scopes');
                if (accessExpirationTime) {
                    data.accessExpirationTime = Number(accessExpirationTime);
                }
                if (expiresIn) {
                    data.expiresIn = Number(expiresIn);
                }
                if (grantedScopes) {
                    const scopes = grantedScopes
                        .split(',')
                        .map(scope => scope.trim());
                    data.grantedScopes = scopes;
                }
                if (deniedScopes) {
                    const scopes = deniedScopes
                        .split(',')
                        .map(scope => scope.trim());
                    data.deniedScopes = scopes;
                }
                return data;
            });
        },
    };
}
/**
 * Deserialize the given `serialized` state from the redirection search params.
 * @returns Deserialized state from he redirection search params.
 */
function deserializeFacebookState(serialized) {
    const buff = Buffer.from(serialized, 'base64');
    return JSON.parse(buff.toString('utf8'));
}
