var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useCreateMessageMutation, useCurrentUserQuery, useInboxMessageAddedSubscription, useInboxMessages, useMarkInboxMessageAsReadMutation, } from '../hooks';
import { useInboxContext } from './InboxContext';
export const InboxMessagesContext = React.createContext(null);
export const InboxMessagesContextProvider = ({ children }) => {
    const { user } = useCurrentUserQuery();
    const { selectedInbox } = useInboxContext();
    const { loading: inboxMessagesLoading, data: inboxMessages, refetch: refetchInboxMessagesQuery, } = useInboxMessages();
    const sendMessage = useCreateMessageMutation();
    const _a = useMarkInboxMessageAsReadMutation(), { mutation: markAsReadMutation } = _a, markAsReadOptions = __rest(_a, ["mutation"]);
    const handleNewInboxAdded = React.useCallback((newInbox) => {
        const isCurrentInbox = (selectedInbox === null || selectedInbox === void 0 ? void 0 : selectedInbox.id) === newInbox.id;
        if (isCurrentInbox)
            refetchInboxMessagesQuery().catch(console.error);
    }, [refetchInboxMessagesQuery, selectedInbox === null || selectedInbox === void 0 ? void 0 : selectedInbox.id]);
    useInboxMessageAddedSubscription({ onNewMessage: handleNewInboxAdded });
    const unreadMessages = React.useMemo(() => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return [];
        return inboxMessages === null || inboxMessages === void 0 ? void 0 : inboxMessages.filter(message => { var _a; return !((_a = message.readBy) === null || _a === void 0 ? void 0 : _a.items.some(recipient => recipient.id === (user === null || user === void 0 ? void 0 : user.id))); });
    }, [inboxMessages, user === null || user === void 0 ? void 0 : user.id]);
    const markAsRead = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedInbox)
            return;
        const messagesIds = unreadMessages.map(m => m.id);
        if (messagesIds.length === 0)
            return;
        yield markAsReadMutation(messagesIds);
    }), [selectedInbox, unreadMessages, markAsReadMutation]);
    return (_jsx(InboxMessagesContext.Provider, Object.assign({ value: {
            inboxMessages,
            inboxMessagesLoading,
            sendMessage,
            unreadMessages,
            markAsRead: Object.assign({ mutation: markAsRead }, markAsReadOptions),
        } }, { children: children })));
};
export const useInboxMessagesContext = () => {
    const context = React.useContext(InboxMessagesContext);
    if (!context) {
        throw new Error('useInboxMessagesContext must be used within InboxMessagesContextProvider');
    }
    return context;
};
