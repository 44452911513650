import { toast } from 'material-react-toastify';
export function useNotification() {
    const success = (message, options) => {
        toast.success(message, options);
    };
    const error = (message, options) => {
        toast.error(message, options);
    };
    const warning = (message, options) => {
        toast.warning(message, options);
    };
    const info = (message, options) => {
        toast.info(message, options);
    };
    const dismiss = (id) => {
        toast.dismiss(id);
    };
    return {
        success,
        error,
        warning,
        info,
        dismiss,
    };
}
