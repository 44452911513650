import { useGetPublicCampaignInfoBySlugQuery, } from '@vizsla/graphql';
import { useSubdomain } from '../shared/useSubdomain';
export const useCurrentPublicCampaign = () => {
    const subdomain = useSubdomain();
    const { data, loading } = useGetPublicCampaignInfoBySlugQuery({
        variables: {
            slug: subdomain,
        },
        skip: !subdomain,
    });
    const publicCampaignInfo = data === null || data === void 0 ? void 0 : data.getPublicCampaignInfoBySlug;
    const organization = (publicCampaignInfo === null || publicCampaignInfo === void 0 ? void 0 : publicCampaignInfo.organization) || null;
    const campaign = (publicCampaignInfo === null || publicCampaignInfo === void 0 ? void 0 : publicCampaignInfo.campaign) || null;
    const experiences = ((publicCampaignInfo === null || publicCampaignInfo === void 0 ? void 0 : publicCampaignInfo.experiences.items) || []);
    const campaignTemplates = publicCampaignInfo === null || publicCampaignInfo === void 0 ? void 0 : publicCampaignInfo.campaignTemplates;
    return { organization, campaign, experiences, campaignTemplates, loading };
};
