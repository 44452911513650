import { isNil } from 'lodash';
import { useReportsQuery, } from '@vizsla/graphql';
/**
 * List all reports for administrators.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-2103
 */
export function useAdministratorReports(options) {
    var _a, _b;
    const { data: response, loading } = useReportsQuery(Object.assign(Object.assign({}, options), { variables: Object.assign({}, options === null || options === void 0 ? void 0 : options.variables) }));
    const data = (_a = response === null || response === void 0 ? void 0 : response.reports.items) !== null && _a !== void 0 ? _a : [];
    const filteredData = data === null || data === void 0 ? void 0 : data.filter(report => isNil(report.organization));
    const count = (_b = filteredData === null || filteredData === void 0 ? void 0 : filteredData.length) !== null && _b !== void 0 ? _b : 0;
    return {
        data: filteredData,
        count,
        loading,
    };
}
