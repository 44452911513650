import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SelectedFieldsContext } from './SelectedFieldsContext';
const SelectedFieldsProvider = ({ children, fields }) => {
    const [selectedFields, setSelectedFields] = React.useState({});
    const onSetSelectedValue = (filterKey, values) => {
        setSelectedFields(fields => {
            const updatedFields = Object.assign({}, fields);
            if (updatedFields[filterKey])
                delete updatedFields[filterKey];
            else
                updatedFields[filterKey] = values;
            return updatedFields;
        });
    };
    const checkEmptyFields = () => {
        const keys = Object.keys(selectedFields) || [];
        const fieldsNonEmpty = keys.filter(key => Object.keys(selectedFields[key]).length !== 0);
        return fieldsNonEmpty.length > 0;
    };
    const checkFieldSelected = (filterKey) => {
        const selectedField = selectedFields[filterKey];
        return selectedField && selectedField.title && selectedField.title.length > 0;
    };
    const hasSelectedFields = checkEmptyFields();
    const onResetSelectedValues = () => {
        setSelectedFields({});
    };
    const onSelectedAllValues = () => {
        const updatedFields = fields === null || fields === void 0 ? void 0 : fields.reduce((acc, { title, key }) => {
            acc[key] = { key, title };
            return acc;
        }, {});
        setSelectedFields(updatedFields);
    };
    return (_jsx(SelectedFieldsContext.Provider, Object.assign({ value: {
            fields,
            selectedFields,
            hasSelectedFields,
            checkFieldSelected,
            onSetSelectedValue,
            onResetSelectedValues,
            onSelectedAllValues,
        } }, { children: children })));
};
SelectedFieldsProvider.displayName = 'SelectedFieldsProvider';
export { SelectedFieldsProvider };
