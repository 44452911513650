import { CampaignAdministrator, CampaignStaff, Checkiner, FinancialAnalyst, OrganizationAdministrator, VizslaAdministrator, VizslaRootUser, } from './permissions';
//    are not valid roles
//   'Vizsla Administrator': 0,
//   'Vizsla Root User': 1,
export const CLIENT_CONSUMER_ROLES = ['Attendee', 'Donor', 'Fundraiser', 'Team Captain'];
export const PERMISSIONS_INDEX = {
    'Collie Administrator': 0,
    'Vizsla Root User': 1,
    'Organization Administrator': 2,
    'Financial Analyst': 3,
    'Campaign Administrator': 4,
    'Campaign Staff': 5,
    Checkiner: 6,
    'Check-In': 6,
};
export const PERMISSIONS_BY_ROLE = [
    VizslaAdministrator,
    VizslaRootUser,
    OrganizationAdministrator,
    FinancialAnalyst,
    CampaignAdministrator,
    CampaignStaff,
    Checkiner,
];
export const PERMISSION_LEVELS = {
    noneAccess: 1,
    view: 2,
    all: 3,
};
export const PERMISSIONS_BY_LEVEL = {
    noneAccess: { hasNoAccess: true, hasLimitedAccess: true },
    view: { hasNoAccess: false, hasLimitedAccess: true },
    all: { hasNoAccess: false, hasLimitedAccess: false },
    test: { hasNoAccess: true, hasLimitedAccess: true },
};
export const SECTION = {
    EDITING_VIZSLA_USERS_PERMISSIONS: { EDIT_USER: 'editingVizslaUsers_EditingVizslaUsers' },
    CAMPAIGN_CREATOR_PERMISSIONS: {
        TICKETING_REGISTRATION: 'campaignCreator_TicketingRegistration',
        PERSONAL_MISSION: 'campaignCreator_PersonalMission',
        IMPACT_GIVING: 'campaignCreator_ImpactGiving',
    },
    ORG_CAMPAIGN_ADMINISTRATION_PERMISSIONS: {
        TICKETING_REGISTRATION: 'campaignAdministration_TicketingRegistration',
        PERSONAL_MISSION: 'campaignAdministration_PersonalMission',
        IMPACT_GIVING: 'campaignAdministration_ImpactGiving',
    },
    FINANCE_PERMISSIONS: {
        EDIT_A_TRANSACTION: 'finance_EditATransaction',
        REFUND_A_TRANSACTION: 'finance_RefundATransaction',
    },
    REPORTING_ANALYTICS_PERMISSIONS: {
        VIEW_REPORTING_ANALYTICS: 'reportingAnalytics_ViewReportingAnalytics',
        CREATE_A_REPORT: 'reportingAnalytics_CreateAReport',
        EXPORT_A_REPORT: 'reportingAnalytics_ExportAReport',
    },
    ASSETS_PERMISSIONS: {
        VIEW_ASSETS: 'assets_ViewAssets',
        ADD_ASSET: 'assets_AddAsset',
        EDIT_ASSET: 'assets_EditAsset',
    },
    PARTNERSHIPS_PERMISSIONS: {
        VIEW_PARTNERSHIPS: 'partnerships_ViewPartnerships',
        ADD_PARTNERSHIP: 'partnerships_AddPartnership',
        EDIT_PARTNERSHIP: 'partnerships_EditPartnership',
    },
    SETTINGS_PERMISSIONS: {
        VIEW_SETTINGS: 'settings_View',
        EDIT_SETTINGS: 'settings_Edit',
    },
    ALL_CAMPAIGNS_VIEW_DASHBOARD_PERMISSIONS: {
        ALL_CAMPAIGNS_VIEW: 'allCampaignsViewDashboard_AllCampaignsView',
        SEARCH: 'allCampaignsViewDashboard_Search',
        FILTER: 'allCampaignsViewDashboard_Filter',
        ADD_PROGRAM: 'allCampaignsViewDashboard_AddProgram',
        CLONE_CAMPAIGN: 'allCampaignsViewDashboard_CloneCampaign',
        DELETE_CAMPAIGN: 'allCampaignsViewDashboard_DeleteCampaign',
    },
    TICKETING_REGISTRATION_CAMPAIGN_DASHBOARD_PERMISSIONS: {
        ADD_MEMBER: 'ticketingRegistrationCampaignDashboard_AddMemberToCampaignDashboard',
        COMPARE_CAMPAIGN: 'ticketingRegistrationCampaignDashboard_CompareCampaign',
        ADD_EDIT_EXPERIENCE: 'ticketingRegistrationCampaignDashboard_AddEditExperience',
        SEARCH_FILTER: 'ticketingRegistrationCampaignDashboard_SearchFilter',
        VIEW_ATTENDEE_PROFILE: 'ticketingRegistrationCampaignDashboard_ViewAttendeeProfile',
        EDIT_ATTENDEE_PROFILE: 'ticketingRegistrationCampaignDashboard_EditAttendeeProfile',
        SEND_TEXT_MESSAGE: 'ticketingRegistrationCampaignDashboard_SendTextMessage',
        COMPOSE_EMAIL: 'ticketingRegistrationCampaignDashboard_ComposeAnEmail',
        ADD_NOTE: 'ticketingRegistrationCampaignDashboard_AddANote',
    },
    PERSONAL_MISSION_CAMPAIGN_DASHBOARD_PERMISSIONS: {
        ADD_MEMBER: 'personalMissionCampaignDashboard_AddMemberToCampaignDashboard',
        SEARCH_FILTER: 'personalMissionCampaignDashboard_SearchFilter',
        ADD_EDIT_MISSION_TYPE: 'personalMissionCampaignDashboard_AddEditMissionType',
        VIEW_ATTENDEE_PROFILE: 'personalMissionCampaignDashboard_ViewAttendeeProfile',
        EDIT_PROFILE: 'personalMissionCampaignDashboard_EditProfile',
        SEND_TEXT_MESSAGE: 'personalMissionCampaignDashboard_SendTextMessage',
        COMPOSE_EMAIL: 'personalMissionCampaignDashboard_ComposeAnEmail',
        ADD_NOTE: 'personalMissionCampaignDashboard_AddANote',
    },
    IMPACT_GIVING_CAMPAIGN_DASHBOARD_PERMISSIONS: {
        ADD_MEMBER: 'impactGivingCampaignDashboard_AddMemberToCampaignDashboard',
        COMPARE_CAMPAIGN: 'impactGivingCampaignDashboard_CompareCampaign',
        VIEW_ATTENDEE_PROFILE: 'impactGivingCampaignDashboard_ViewAttendeeProfile',
        EDIT_PROFILE: 'impactGivingCampaignDashboard_EditProfile',
        SEND_TEXT_MESSAGE: 'impactGivingCampaignDashboard_SendTextMessage',
        COMPOSE_EMAIL: 'impactGivingCampaignDashboard_ComposeAnEmail',
        ADD_NOTE: 'impactGivingCampaignDashboard_AddANote',
    },
    CAMPAIGN_BASIC_SECTION_ALL_CAMPAIGNS_PERMISSIONS: {
        DETAILS: 'campaignBasicSectionAllCampaigns_Details',
        CONTACT: 'campaignBasicSectionAllCampaigns_Contact',
        QUESTIONS: 'campaignBasicSectionAllCampaigns_Questions',
        FAQ: 'campaignBasicSectionAllCampaigns_FAQ',
    },
    TICKETING_REGISTRATION_AND_PERSONAL_MISSION_FUNDRAISING_SECTION_ALL_CAMPAIGNS_PERMISSIONS: {
        DONATION_SETTINGS: 'ticketingRegistrationAndPersonalMissionFundraisingSectionAllCampaigns_DonationSettings',
    },
    IMPACT_GIVING_FUNDRAISING_SECTION_PERMISSIONS: {
        DONATION_SETTINGS: 'impactGivingFundraisingSectionPermissions_DonationSettings',
    },
    DESIGN_CAMPAIGN_PERMISSIONS: {
        EDIT_CAMPAIGN_PAGES: 'designCampaign_EditCampaignPages',
        EDIT_COMMUNICATIONS: 'designCampaign_EditCommunications',
    },
    CAMPAIGN_PEOPLE_SECTION_PERMISSIONS: {
        SEARCH_FILTER: 'campaignPeopleSection_SearchFilter',
        ADD_PEOPLE: 'campaignPeopleSection_AddPeople',
        VIEW_ATTENDEE_PROFILE: 'campaignPeopleSection_ViewAttendeeProfile',
        EDIT_PROFILE: 'campaignPeopleSection_EditProfile',
        SEND_MESSAGE: 'campaignPeopleSection_SendMessage',
    },
    CAMPAIGN_CHECK_IN_PERMISSIONS: { EDIT: 'campaignCheckIn_Edit' },
    CAMPAIGN_ADMINISTRATION_PERMISSIONS: { SETTINGS: 'campaignAdministration_AdminSettings' },
    CAMPAIGN_DISCOUNTS_PERMISSIONS: {
        SEARCH: 'campaignDiscounts_Search',
        ADD_DISCOUNT: 'campaignDiscounts_AddDiscount',
        CLONE_DISCOUNT: 'campaignDiscounts_CloneDiscount',
        DELETE_DISCOUNT: 'campaignDiscounts_DeleteDiscount',
    },
    TEAM_EXPERIENCE_PERMISSIONS: { EDIT: 'teamExperience_ManageOnlyThisExperience' },
};
