import React from 'react';
import { useUsersListQuery } from '@vizsla/graphql';
import { useInboxFilters } from '@vizsla/providers/dist/InboxProvider/hooks';
import { useCurrentUser } from '../user';
var SectionType;
(function (SectionType) {
    SectionType["Consumer"] = "consumer";
    SectionType["Organization"] = "organization";
    SectionType["Fundraising"] = "fundraising";
    SectionType["Team"] = "team";
})(SectionType || (SectionType = {}));
const buildMyNetworkUsersFilter = ({ search, userFilter }) => {
    const filters = [mapSearchToFilter(search), userFilter].filter(Boolean);
    return { AND: filters };
};
const mapSearchToFilter = (search) => {
    const hasSearch = search && search.length > 0;
    if (!hasSearch)
        return null;
    return { OR: [{ firstName: { contains: search } }, { lastName: { contains: search } }] };
};
export const buildOrganizationFilter = (organizationIds, section) => {
    const organizationFilter = organizationIds === null || organizationIds === void 0 ? void 0 : organizationIds.map(id => ({
        vizslaOrganization: { id: { equals: id } },
    }));
    if (section === SectionType.Consumer) {
        return [
            {
                vizslaOrganizationStaff: {
                    some: { vizslaOrganization: { id: { in: organizationIds } } },
                },
            },
        ];
    }
    return [
        {
            attendees: {
                some: {
                    experience: {
                        campaign: {
                            OR: organizationFilter,
                        },
                    },
                },
            },
        },
        {
            allocatedDonations: {
                some: {
                    campaign: {
                        OR: organizationFilter,
                    },
                },
            },
        },
        {
            vizslaOrganizationStaff: {
                some: {
                    OR: organizationFilter,
                },
            },
        },
    ];
};
export function useNetworkUsersQuery(search) {
    const { userFilter } = useInboxFilters();
    const { user } = useCurrentUser();
    const { data, loading, error } = useUsersListQuery({
        skip: !user,
        variables: {
            filter: buildMyNetworkUsersFilter({
                userFilter,
                search,
            }),
            first: 500,
        },
        fetchPolicy: 'cache-first',
    });
    const users = React.useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.usersList) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []; }, [data]);
    return { users, loading, error };
}
